import '@/assets/css/base.scss';
import Swiper from 'swiper';
import '@/assets/css/liMarquee.css';
import '@/assets/js/jquery.liMarquee.js';
import '@/component/main/main.js';

$(function(){
    $('body').addClass("index");
    // index news left
    var newsSlides1 = $('.newsSlides1');
    var swiper = new Swiper(newsSlides1.find('.swiper-container'), {
        autoplay: {
            delay: 2500,
            disableOnInteraction: false,
          },
        pagination: {
          el: newsSlides1.find('.swiper-pagination'),
          clickable: true,
        },
      });

    //   index news right
    $('.m-dowebok').liMarquee({
        direction: 'up',
        runshort: false,
        scrollamount: 50 //数字越大，速度越快
    });
    // index banbox 
    var indexBanbox = $('.indexBanbox'); 
    var swiper = new Swiper(indexBanbox.find('.swiper-container'), {
        slidesPerView: 1,
        spaceBetween: 0, 
        pagination: {
          el: indexBanbox.find('.swiper-pagination'),
          clickable: true,
        },
        navigation: {
          nextEl: indexBanbox.find('.swiper-button-next'),
          prevEl: indexBanbox.find('.swiper-button-prev'),
        },
    });
    // m-indexNav
    $(window).scroll(function(){
      var ws = $(window).scrollTop();
      if( ws > $(".indexBanbox").height()){
          $(".m-indexNav").addClass("active"); 
      }else{
          $(".m-indexNav").removeClass("active");  
       }
  });
})  

// video
$('.m-default-video').find('.m-btnMore').click(function(){
  $('.m-default-hidden .m-videoBox').addClass('active');
});
$('.m-videoBox').each(function(){
  var _videoBox = $('.m-videoBox');
  _videoBox.find('.m-btnPlaypop').click(function(){
    $('.m-popVideoMask').addClass('active');
  });
  _videoBox.find('.m-btnClose-video').click(function(){
    $(this).parent().removeClass('active');
  });
});
$('.poster i').click(function(){
  $(this).parent().html('<iframe src="'+ $(this).attr('data') +'?rel=0&autoplay=1" allowFullScreen="true"></iframe>');
});
$('.m-popVideoMask').find('.m-btnClosepvm').click(function(){
  $('.m-popVideoMask').removeClass('active');
});